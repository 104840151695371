import React, {useContext} from 'react';
import cx from 'classnames';
import HeartIcon from '../../../public/static/theme-capcar/heart-favorite-filled.svg';
import Link, {LinkType} from '../../system/Link';
import {I18nContext} from '../../../i18n';

const FavoriteLink = () => {
	const {translate} = useContext(I18nContext);
	return (
		<Link
			href="/mes-favoris"
			prefetch={false}
			type={LinkType.IN_APP}
			className="w-full tablet:w-auto flex items-center mt-4 lg:mt-0 hidden laptop:block"
		>
			<a
				className={cx(
					'px-1 desktop:px-3 pt-2 flex flex-col items-center text-white h-full',
				)}
				rel="nofollow"
			>
				<div className="flex">
					<HeartIcon className="w-4 h-4 mr-2 self-center" />{' '}
					{translate('favoriteMenuLabel')}
				</div>
			</a>
		</Link>
	);
};

export default FavoriteLink;
